@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full font-site-base text-site-base;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  /* Buttons */

  .btn--conversion {
    @apply btn--base btn--spacing btn--pseudo text-white bg-site-conversion border-site-conversion btn--pseudo--conversion;
  }

  .btn--accent {
    @apply btn--base btn--spacing btn--pseudo !text-white bg-site-accent border-site-accent btn--pseudo--accent;
  }

  .btn--outline--conversion {
    @apply btn--base btn--spacing btn--pseudo [&:not(:hover)]:border--fix text-site-conversion border-site-conversion btn--pseudo--conversion;
  }

  .btn--outline--accent {
    @apply btn--base btn--spacing btn--pseudo [&:not(:hover)]:border--fix text-site-accent border-site-accent btn--pseudo--accent;
  }

  .btn--outline--black {
    @apply btn--base btn--spacing btn--pseudo [&:not(:hover)]:border--fix text-site-base border-site-base btn--pseudo--conversion;
  }

  .btn--outline--white {
    @apply btn--base btn--spacing btn--pseudo [&:not(:hover)]:border--fix text-white border-white btn--pseudo--conversion;
  }

  /* Styling */

  .label--outline--black {
    @apply label--base border-site-base;
  }

  .label--outline--grey {
    @apply label--base border-[#A4A4A4] text-[#A4A4A4];
  }

  .label--black--small {
    @apply label--small--base bg-site-base;
  }

  .label--grey--small {
    @apply label--small--base bg-site-grey-dark;
  }

  /* Utils */

  .section--unit {
    @apply section--unit--pt section--unit--pb;
  }

  .section--unit--pt {
    @apply pt-12 md:pt-20 lg:pt-36 xl:pt-56;
  }

  .section--unit--pb {
    @apply pb-12 md:pb-20 lg:pb-36 xl:pb-56;
  }

  /* Margins for sections without a background */
  .section--large--mt {
    @apply mt-20 lg:mt-28 xl:mt-36;
  }

  .section--large--mb {
    @apply mb-20 lg:mb-28 xl:mb-36;
  }

  .-section--large--mt {
    @apply -mt-20 lg:-mt-28 xl:-mt-36;
  }

  .-section--large--mb {
    @apply -mb-20 lg:-mb-28 xl:-mb-36;
  }

  /* Paddings for sections with a background */
  .section--large--pt {
    @apply pt-20 lg:pt-28 xl:pt-36;
  }

  .section--large--pb {
    @apply pb-20 lg:pb-28 xl:pb-36;
  }

  /* Margins for sections without a background */
  .section--mt {
    @apply mt-10 md:mt-20 lg:mt-28 xl:mt-32;
  }

  .section--mb {
    @apply mb-10 md:mt-20 lg:mb-28 xl:mb-32;
  }

  /* Paddings for sections with a background */
  .section--pt {
    @apply pt-12 lg:pt-20;
  }

  .section--pb {
    @apply pb-12 lg:pb-20;
  }

  .section--large:not(.section--with-bg) {
    @apply section--large--mt section--large--mb;
  }

  .section--large.section--with-bg {
    @apply section--large--pt section--large--pb;
  }

  .section:not(.section--with-bg) {
    @apply section--mt section--mb;
  }

  .section.section--with-bg {
    @apply section--pt section--pb;
  }

  .section--small {
    @apply py-6 lg:py-8;
  }

  .content--accent {
    @apply content--base content--pseudo content--paragraphs content--headings text-site-accent children-p:text-site-accent children-headings:text-site-accent;
  }

  .content--light {
    @apply content--base content--pseudo--light content--paragraphs content--headings text-site-base children-p:text-site-base children-headings:text-site-base;
  }

  .content--dark {
    @apply content--base content--pseudo--dark content--paragraphs content--headings text-white children-p:text-white children-headings:text-white;
  }

  .content--thiccer {
    @apply content--base content--pseudo content--paragraphs content--headings children-p:text-xl sm:children-p:text-2xl lg:children-p:text-3xl children-p:!leading-[32px] lg:children-p:!leading-[34px] children-p:font-bold;
  }

  .content--thicc {
    @apply content--base content--pseudo content--paragraphs content--headings children-p:text-lg sm:children-p:text-xl lg:children-p:text-2xl children-p:!leading-[32px] lg:children-p:!leading-[34px] children-p:font-bold;
  }

  .content--slighty-thicc {
    @apply content--base content--pseudo content--paragraphs content--headings children-p:text-base sm:children-p:text-lg children-p:font-bold sm:children-p:!leading-[32px];
  }

  /* .content--blog {
    @apply children-strong:text-site-base children-strong:text-xl;
  } */
}

@layer utilities {
  /* Buttons */

  .btn--pseudo--accent {
    @apply [&:not([disabled])]:focus:bg-site-accent-focus [&:not([disabled])]:focus:text-white [&:not([disabled])]:hover:bg-site-accent-hover [&:not([disabled])]:hover:border-site-accent-hover [&:not([disabled])]:hover:text-white;
  }

  .btn--pseudo--conversion {
    @apply [&:not([disabled])]:focus:bg-site-conversion-focus [&:not([disabled])]:focus:text-white [&:not([disabled])]:hover:bg-site-conversion-hover [&:not([disabled])]:hover:border-site-conversion-hover [&:not([disabled])]:hover:text-white;
  }

  .btn--psueod--link {
    @apply hover:bg-site-grey-light/50 hover:underline;
  }

  .btn--pseudo {
    @apply transition-colors disabled:opacity-50 [&:not([disabled])]:hover:cursor-pointer;
  }

  .btn--spacing {
    @apply py-2 px-5;
  }

  .btn--base {
    @apply border-2 rounded-full inline-flex items-center gap-3 justify-center font-bold text-center;
  }

  .btn--base--link {
    @apply px-1 rounded-md;
  }

  /* Styling */

  .gradient--background {
    background: linear-gradient(101deg, rgba(255, 255, 255, 0.8) 45.95%, rgba(255, 255, 255, 0.6) 110.6%);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .select--base {
    @apply appearance-none pr-10 outline-none;
  }

  .select--icon {
    @apply appearance-none bg-no-repeat;

    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='6 9 12 15 18 9'></polyline></svg>");
    background-position-x: calc(100% - 12px);
    background-position-y: 6px;
  }

  .label--base {
    @apply border [&:not(:hover)]:border--fix rounded-full font-light px-3 py-1 text-[10px] xs:text-xs;
  }

  /* Content */

  .content--pseudo--dark {
    @apply content--pseudo children-a:text-site-accent;
  }

  .content--pseudo--light {
    @apply content--pseudo children-a:text-site-conversion;
  }

  .content--pseudo {
    @apply children-strong:text-site-accent children-a:underline;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold children-ul:mb-4;
  }

  .content--paragraphs {
    @apply children-p:leading-7 children-p:mb-4 sm:children-p:mb-6 children-p:font-light sm:children-p:font-normal;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold;
  }

  .content--white {
    @apply children:!text-white;
  }

  /* Sizes */

  .title--chonker {
    @apply text-[150px] leading-[0.7] font-bold;
  }

  .title--unit {
    @apply text-[100px] lg:text-[110px] leading-[0.7] font-bold;
  }

  .title--semi-unit {
    @apply text-6xl md:text-7xl lg:text-[100px] leading-[0.8] font-bold;
  }

  .title--large {
    @apply text-5xl sm:text-6xl lg:text-7xl xl:text-[90px] leading-[0.9] font-bold;
  }

  .title--semi-large {
    @apply text-[40px] sm:text-5xl lg:text-[60px] leading-[1] font-bold;
  }

  .title--medium {
    @apply text-3xl md:text-4xl lg:text-[44px] leading-[1] font-bold;
  }

  .title--semi-medium {
    @apply text-2xl md:text-3xl lg:text-[36px] leading-[1] font-bold;
  }

  .title--normal {
    @apply text-xl lg:text-2xl xl:text-3xl font-bold;
  }

  .title--small {
    @apply text-lg sm:text-xl lg:text-2xl font-bold;
  }

  .title--light {
    @apply text-xl font-bold;
  }

  .title--tiny {
    @apply text-lg font-bold;
  }

  /* Spacing */

  .spacing--big {
    @apply spacing--big--top spacing--big--bottom;
  }

  .spacing--big--top {
    @apply mt-10 sm:mt-16;
  }

  .spacing--big--bottom {
    @apply mb-10 sm:mb-16;
  }

  .spacing--normal {
    @apply spacing--normal--top spacing--normal--bottom;
  }

  .spacing--normal--top {
    @apply mt-4 sm:mt-10;
  }

  .spacing--normal--bottom {
    @apply mb-4 sm:mb-10;
  }

  .spacing--semi-small {
    @apply spacing--semi-small--top spacing--semi-small--bottom;
  }

  .spacing--semi-small--top {
    @apply mt-8;
  }

  .spacing--semi-small--bottom {
    @apply mb-8;
  }

  .spacing--small {
    @apply spacing--small--top spacing--small--bottom;
  }

  .spacing--small--top {
    @apply mt-3 sm:mt-6;
  }

  .spacing--small--bottom {
    @apply mb-3 sm:mb-6;
  }

  /* Utils */

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .flex--center {
    @apply flex items-center justify-center;
  }

  .border--fix {
    box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  }

  .border-text {
    -webkit-text-stroke: 1.5px black;
  }

  .border-text--thicc {
    -webkit-text-stroke: 2px black;
  }

  .border-text--white {
    -webkit-text-stroke: 1.5px white;
  }

  .label--small--base {
    @apply rounded-full text-xs flex items-center gap-1 text-white py-[2px] xs:py-1 px-2 xs:px-3 btn--pseudo;
  }

  .list--stripe {
    @apply children-ul:flex children-ul:flex-col children-ul:gap-2 lg:children-ul:gap-3 children-ul:pl-8 children-ol:flex children-ol:flex-col children-ol:gap-2 children-ol:pl-8 children-li:before:absolute children-li:relative children-li:before:-left-6 children-li:before:mt-[10px] children-li:before:block children-li:before:h-[2px] children-li:before:min-w-[12px] children-li:before:max-w-[12px];
  }

  .list--stripe--accent {
    @apply list--stripe children-li:before:bg-site-accent;
  }

  .list--stripe--white {
    @apply list--stripe children-li:before:bg-white;
  }

  .list--crosshair {
    @apply children-ul:ml-10 sm:children-ul:text-lg children-ul:text-base children-ul:flex children-ul:flex-col children-ul:gap-6  children-li:relative;
  }

  .list--crosshair li:before {
    content: '';
    width: 23px;
    height: 23px;
    max-width: 23px;
    background: url('/static/crosshair.svg') no-repeat center center;
    flex: none;
    left: -40px;
    top: 2px;
    position: absolute;
  }

  .list--checkmark {
    @apply sm:children-ul:text-lg children-ul:pl-3 sm:children-ul:pl-6 children-ul:text-base children-ul:flex children-ul:flex-col children-ul:gap-6 children-li:flex children-li:gap-5 children-li:items-start children-li:before:mt-[10px] children-li:before:block children-li:before:h-[2px] children-li:before:min-w-[12px] children-li:before:max-w-[12px];
  }

  .list--checkmark li:before {
    width: 23px;
    height: 23px;
    max-width: 23px;
    background: url('/static/checkmark.svg') no-repeat center center;
    margin-top: 3px;
    flex: none;
  }

  blockquote p::before {
    content: '“';
  }

  blockquote p::after {
    content: '”';
  }

  /* Pagination */

  a.pagination--item,
  .pagination--item > a,
  .pagination--button > a {
    @apply h-[32px] xs:h-[38px] no-underline max-xs:text-sm flex--center block bg-white border border-site-grey text-site-base/40 font-bold btn--pseudo--conversion;
  }

  a.pagination--item,
  .pagination--item > a {
    @apply w-[30px] xs:w-[40px];
  }

  .pagination--button > a {
    @apply px-2 xs:px-4;
  }

  /* Header */

  .header--height {
    @apply h-[100px];
  }

  .header--padding--top {
    @apply pt-[100px];
  }

  .header--margin--top--negative {
    @apply -mt-[100px];
  }

  /* Styling */

  .block--shadow {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  /* Range slider */
  .range--slider {
    @apply bg-site-grey appearance-none w-full h-[6px] rounded-md outline-none;

    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .range--slider::-webkit-slider-thumb {
    @apply bg-site-accent;

    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
  }

  .range--slider::-moz-range-thumb {
    @apply bg-site-accent;

    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
  }

  .custom-breadcrumb a {
    @apply text-site-grey-other hover:text-site-base transition-all duration-200;
  }

  .custom-breadcrumb li:last-of-type span,
  .custom-breadcrumb li:last-of-type a {
    @apply !text-site-accent;
  }

  .form--grey .form-duuf-label {
    @apply block lg:text-lg font-bold text-site-base mb-1;
  }

  .form--grey .form-duuf-input {
    @apply bg-site-grey-light;
  }

  .form--dark .form-duuf-checkbox-label {
    @apply text-white pl-3;
  }
}

.disable-animations * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  animation: none !important;
}

#CybotCookiebotDialogPoweredbyCybot {
  display: none;
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-site-accent h-2 rounded-r-full;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-group {
  @apply mb-5;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 flex--center;
}

.form-duuf-label {
  @apply block lg:text-lg font-bold text-white mb-1;
}

.form-duuf-input {
  @apply rounded-full h-[36px];
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply border-2 border-white text-site-base px-4 w-full text-sm focus:border-site-accent placeholder:text-site-base/50;
}

.form-duuf-textarea {
  @apply h-24 py-2 rounded-[10px];
}

.form-duuf-select__control {
  @apply outline-none;
}

.form-duuf-error {
  @apply text-xs text-red-400 -mt-2 mb-1;
}

.form-duuf-group-submit {
  @apply pt-4;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-group-submit {
  @apply mb-0;
}

.form-duuf-file-button button {
  @apply btn--outline--black py-[2px];
}

.form-duuf-group-name .form-duuf-group {
  @apply w-full;
}

.form-duuf-group-name {
  @apply max-xs:block;
}

.form-duuf-group-consent > div {
  @apply gap-3;
}

.form-duuf-group-consent input {
  @apply w-4 h-4;
}

/* Form Light Theme */

.form--light .form-duuf-label {
  @apply block lg:text-lg font-bold text-site-base mb-1;
}

.form-duuf-group-checkbox > div {
  @apply grid grid-cols-2 gap-2 md:gap-3 py-2 md:px-5;
}

.form-duuf-group-checkbox > div > div {
  @apply items-start;
}

.form-duuf-group-checkbox > div > div > input {
  @apply mt-[6px] mr-2 !rounded-lg min-w-[16px] min-h-[16px] border-none outline-none;
}

.form-duuf-group-checkbox > div > div > label {
  @apply text-sm leading-[1] pt-[6px];
}

.form-duuf-file-name {
  @apply pl-2;
}

/* Start - Form Apply */

#form_2 .form-duuf-input {
  @apply h-[48px];
}

#form_2 .form-duuf-file-button,
#form_8 .form-duuf-file-button {
  @apply bg-site-grey-light rounded-3xl px-4 py-2 flex items-center gap-4;
}

#form_2 .form-duuf-file-button,
#form_8 .form-duuf-file-button {
  @apply max-sm:w-full max-sm:justify-between;
}

#form_2 .form-duuf-file-button::before,
#form_8 .form-duuf-file-button::before {
  content: 'PDF, DOC, DOCX (Max. 4MB)';
  display: block;
  @apply text-sm opacity-50;
}

#form_2 .form-duuf-file,
#form_8 .form-duuf-file {
  @apply flex-wrap gap-4;
}

#form_2 .form-duuf-group-fileupload,
#form_2 .form-duuf-group-consent,
#form_2 > div:last-child {
  @apply col-span-2;
}

#form_2 .form-duuf-file-name {
  @apply order-1 font-bold text-site-accent text-lg;
}

#form_2 .form-duuf-submit > button {
  @apply max-sm:w-full;
}

#form_2 .form-duuf-file::after {
  content: 'Voor een snelle en juiste match hebben we je CV echt nodig!';
  display: block;
  @apply text-white/50 sm:text-site-base text-[11px] sm:w-44 max-sm:w-full max-sm:text-center max-sm:-mt-2;
}

#form_2 .form-duuf-label-mandatory {
  @apply hidden;
}

#form_10 .form-duuf-checkbox-label {
  @apply text-white;
}

/* End - Form Apply */

/* Custom */

@keyframes fade--light {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes fade-up--light {
  0% {
    opacity: 0;
    transform: translateY(2rem /* 32px */);
  }
  100% {
    opacity: 0.25;
    transform: translateY(0);
  }
}

.losse-kruumel-history {
  @apply flex items-center text-site-grey-other gap-2 children-span:mx-0 children-span:rotate-180;
}

.animate-fade--light {
  animation: fade--light 1s both;
}

.animate-fade-up--light {
  animation: fade-up--light 1s both;
}

.form--perfect-match .form-duuf-file-button button,
.form--perfect-match .form-duuf-group-checkbox .form-duuf-checkbox-label {
  @apply text-white;
}

.content--slighty-thicc .btn--conversion,
.content--light .btn--accent {
  @apply no-underline;
}

/* Banner animation */

@media (min-height: 1100px) {
  .header-animation-crosshair {
    @apply top-[475px];
  }
}

@media (max-height: 1099px) {
  .header-animation-crosshair {
    @apply top-[400px];
  }
}

@media (max-height: 999px) {
  .header-animation-crosshair {
    @apply top-[375px];
  }
}

/* Match & Onboarding */

@media (min-height: 1000px) {
  .match-onboarding-image {
    @apply top-[190px];
  }

  .match-onboarding-title {
    @apply max-md:hidden mb-20 xl:title--semi-unit xl:!leading-[110px] title--large;
  }

  .match-onboarding-crosshair {
    @apply top-[367px] sm:top-[407px] lg:top-[459px];
  }
}

@media (max-height: 999px) {
  .match-onboarding-next {
    @apply hidden;
  }

  .match-onboarding-image {
    @apply top-[50px];
  }

  .match-onboarding-title {
    @apply max-md:hidden mb-10 title--semi-large lg:title--large !leading-[60px] lg:!leading-[80px];
  }

  .match-onboarding-crosshair {
    @apply top-[227px] sm:top-[266px] lg:top-[369px];
  }
}

/* TABLE */
.table-container {
  width: 100%;
  overflow-x: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}
th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}
th {
  background-color: #f4f4f4;
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: visible;
  }

  table {
    min-width: 0;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead {
    display: none;
  }
  tr {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    background: #fff;
  }
  td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  td:last-child {
    border-bottom: none;
  }
}
